import React, { useEffect, useState } from 'react';

import { useRecoilState } from 'recoil';

import { userAuthenticatedState, pendingReportJobsState } from './atoms';

import { getIsAuthenticated, getReportJob } from '@oddular/oddular-commerce-js';

import { useToast } from '@chakra-ui/react';
import { report } from 'process';

import { CORE_URL } from '@lib/constants';
import { watch } from 'fs';

import AuthenticationOverlay from '@components/AuthenticationOverlay'

interface satelliteProps {}

const Satellite: React.FC<satelliteProps> = ({}) => {
  const toast = useToast();

  const [userAuthenticated, setUserAuthenticated] = useRecoilState(userAuthenticatedState);
  const [pendingReportJobs, setPendingReportJobs] = useRecoilState(
    pendingReportJobsState,
  );
  const [watchedReportJobs, setWatchedReportJobs] = useState([])


  useEffect(() => {
    // Check that user is authenticated
    getIsAuthenticated(CORE_URL).then((data: any) => {
      setUserAuthenticated({ ...data, loading: false });
      if (!data.authenticated) {
        window.location.replace(process.env.NEXT_PUBLIC_ODDULAR_AUTH_URL);
      } else if (window.location.pathname === '/') {
        window.location.replace('/create-report');
      }
    });
  }, []);

  useEffect(() => {


    const watchReportJob = (id: string) => {
      getReportJob(id, CORE_URL)
        .then((response) => response.data)
        .then((reportJob: any) => {
          if (reportJob.status === 'READY') {
            toast({
              title: 'Report Job Complete',
              description: `Refresh the report jobs page to download your report.`,
              status: 'success',
              duration: 9000,
              isClosable: true,
            });
            setPendingReportJobs(pendingReportJobs.filter(rj => rj.id !== id))
          } else if (reportJob.status === 'ERROR') {
            toast({
              title: 'Report Job Error',
              description: `There was an error when generating your report.`,
              status: 'error',
              duration: 9000,
              isClosable: true,
            });
            setPendingReportJobs(
              pendingReportJobs.filter((rj) => rj.id !== id),
            );
          }
          else {
            setTimeout(() => watchReportJob(id), 5000);
          }
        });
    };

    pendingReportJobs.forEach((reportJob) => {
      if (!watchedReportJobs.includes(reportJob.id)) {
        setWatchedReportJobs([...watchedReportJobs, reportJob])
        watchReportJob(reportJob.id);
      }
    });
  }, [pendingReportJobs]);

  return !!userAuthenticated ? null : <AuthenticationOverlay />;
};

export default Satellite;

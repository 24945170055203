import { mode } from '@chakra-ui/theme-tools';
import { redirect } from 'next/dist/next-server/server/api-utils';

const parts = ['item', 'command', 'list', 'button', 'groupTitle'];

function baseStyleList(props: Record<string, any>) {
  return {
    bg: mode(`#fff`, `gray.100`)(props),
    color: 'black',
    boxShadow: mode(`sm`, `dark-lg`)(props),
    px: '2px',
    minW: '3xs',

    borderRadius: '.35rem',
    borderWidth: '0.01rem',
    userSelect: 'none',
    cursor: 'default',
  };
}

function baseStyleItem(props: Record<string, any>) {
  return {
    py: '0.1rem',
    px: '0.8rem',
    my: '1px',
    fontSize: 'sm',
    fontWeight: '500',
    transition: 'all 50ms ease-in 0s',
    borderRadius: '0.35rem',
    _focus: {
      bg: mode(`blue.400`, `whiteAlpha.100`)(props),
      color: 'white',
    },
    _active: {
      bg: mode(`blue.400`, `whiteAlpha.200`)(props),
      color: 'white',
    },
    _expanded: {
      bg: mode(`blue.400`, `whiteAlpha.100`)(props),
      color: 'white',
    },
    _disabled: {
      opacity: 0.4,
      cursor: 'not-allowed',
    },
  };
}

const baseStyleGroupTitle = {
  py: '0.1rem',
  px: '0.8rem',
  m: '0',
  fontWeight: 'semibold',
  opacity: '.5',
  textTransform: 'uppercase',
  fontSize: '0.65rem',
};

const baseStyleCommand = {
  opacity: 0.6,
};

const baseStyle = function (props: Record<string, any>) {
  return {
    list: baseStyleList(props),
    item: baseStyleItem(props),
    groupTitle: baseStyleGroupTitle,
    command: baseStyleCommand,
  };
};

export default {
  parts,
  baseStyle,
};

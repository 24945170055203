import { Box, VisuallyHidden, keyframes, useToken } from '@chakra-ui/react';

import * as React from 'react';

const spin = keyframes({
  '0%': {
    transform: 'rotate(0deg)',
  },
  '100%': {
    transform: 'rotate(360deg)',
  },
});



interface SpinnerProps {
  /**
   * The color of the empty area in the spinner
   */
  emptyColor?: string;
  /**
   * The color of the spinner
   */
  color?: string;
  /**
   * The speed of the spinner.
   * @example
   * ```jsx
   * <Spinner speed="0.2s"/>
   * ```
   */
  speed?: string;
  /**
   * For accessibility, it is important to add a fallback loading text.
   * This text will be visible to screen readers.
   */
  label?: string;
  size?: string;
  delay?: string;
}

export const Spinner: React.FC<SpinnerProps> = ({
  color = 'black',
  emptyColor = 'transparent',
  speed = "2s",
  size = "32px",
  delay = "0s",
  label,
}) => {
  const [_color, _emptyColor] = useToken('colors', [color, emptyColor]);
  return (
    <Box p={1}>
      <Box
        as="svg"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 159.94 159.92"
        animation={`${spin} ${speed} infinite`}
        style={{ animationTimingFunction: 'cubic-bezier(0.5, 0.7, 0.9, 0.9)', animationDelay: delay }}
      >
        <path
          d="M177.8,75.1a166.32,166.32,0,0,0-52.9-53,12.88,12.88,0,0,0-7.6-2.1A165.27,165.27,0,0,0,44.9,39.3a12.94,12.94,0,0,0-5.6,5.5,165.87,165.87,0,0,0-19.5,72.4,13.55,13.55,0,0,0,2,7.6,163.58,163.58,0,0,0,23.5,29.6,162.37,162.37,0,0,0,29.5,23.4,13.5,13.5,0,0,0,7.8,2.1,166.38,166.38,0,0,0,72.1-19.3,12.94,12.94,0,0,0,5.6-5.5,165,165,0,0,0,19.4-72.4A12.89,12.89,0,0,0,177.8,75.1Zm-46.4,56.5a44.76,44.76,0,1,1,0-63.3A44.72,44.72,0,0,1,131.4,131.6Z"
          transform="translate(-19.79 -19.99)"
          fill={_color}
        />
        <path
          d="M131.41,131.6a44.76,44.76,0,1,1,0-63.3A44.72,44.72,0,0,1,131.41,131.6Z"
          transform="translate(-19.79 -19.99)"
          fill={_emptyColor}
        />
        {label && <VisuallyHidden>{label}</VisuallyHidden>}
      </Box>
    </Box>
  );
};

export default Spinner;

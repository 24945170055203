import { mode, Styles } from '@chakra-ui/theme-tools';

const styles: Styles = {
  global: (props) => ({
    body: {
      fontFamily: 'body',
      color: mode('gray.800', 'whiteAlpha.900')(props),
      backgroundSize: 'cover',
      transition: 'background-color 0.2s',
      lineHeight: 'base',
    },
    '*::placeholder': {
      color: mode('gray.400', 'whiteAlpha.400')(props),
    },
    '*, *::before, &::after': {
      borderColor: mode('gray.200', 'whiteAlpha.300')(props),
      wordWrap: 'break-word',
    },
    '.active-scroll': {
      color: 'blue.500',
    },
    '.card': {
      bg: 'blackAlpha.50',
    },
    '.card .card-header': {
      bg: 'whiteAlpha.900',
    },
    '.odd-table': {
      cursor: 'default',
    },
    '.odd-table-row, .odd-table-header': {
      borderRadius: '.45rem',
      padding: '0 1rem',
      display: 'flex',
      alignItems: 'center',
    },
    '.odd-table-row': {
      transition: 'background .05s ease-in',
      borderWidth: '1px',
      borderStyle: 'dashed',
      borderColor: 'transparent',
    },
    '.odd-table-row:hover': {
      borderColor: 'blackAlpha.500',
    },

    '.odd-table-cell': {
      p: 1,
    },
    '.resizer': {
      display: 'inline-block',
      background: 'blue',
      width: '10px',
      height: '100%',
      position: 'absolute',
      right: 0,
      top: 0,
      transform: 'translateX(50%)',
      zIndex: '1',
    },
    '@media only screen and (max-width: 600px)': {
      '.odd-mobile-menu': {
        position: 'fixed !important',
        bottom: '0 !important',
        width: '100vw !important',
        left: '0 !important',
        right: '0 !important',
        background: 'red !important',
        border: '20px solid green',
      },
    },
  }),
};

export default styles;

import colors from './_colors'
import components from './_components'
import styles from './_styles';
import typography from './_typography'
import sizes from './_sizes'

const theme = {
  colors,
  components,
  sizes,
  styles,
  typography
}

export default theme

import React from 'react';

import { Box, Text } from '@chakra-ui/react';
import Spinner from '@components/Spinner';

interface AuthenticationOverlayProps {}

const AuthenticationOverlay: React.FC<AuthenticationOverlayProps> = ({}) => {
  return (
    <Box
      position="fixed"
      top="0"
      left="0"
      width="100vw"
      height="100vh"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      bg="whiteAlpha.800"
      className="blur-filter"
    >
      <Spinner />
      <Text>Authenticating...</Text>
    </Box>
  );
};

export default AuthenticationOverlay;

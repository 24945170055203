import React from 'react';

import Head from 'next/head';

interface MetaProps {}

const Meta: React.FC<MetaProps> = ({}) => {
  return (
    <Head>
      <meta charSet="utf-8" />

      <title>Oddular</title>

      <meta name="robots" content="noindex" />

      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta http-equiv="Content-Language" content="en" />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@oddularhq" />
      <meta name="twitter:image" content="/twitter-card.png" />
      <meta property="og:image" content="/twitter-card.png" />

      <meta name="apple-mobile-web-app-title" content="Oddular" />
      <meta name="theme-color" content="#ffffff"></meta>
      <meta name="msapplication-TileColor" content="#000000" />

      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon/favicon-16x16.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="57x57"
        href="/favicon/favicon_57x57.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="60x60"
        href="/favicon/favicon_60x60.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="72x72"
        href="/favicon/favicon_72x72.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="76x76"
        href="/favicon/favicon_76x76.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="114x114"
        href="/favicon/favicon_114x114.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="120x120"
        href="/favicon/favicon_120x120.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="144x144"
        href="/favicon/favicon_144x144.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="152x152"
        href="/favicon/favicon_152x152.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/favicon/favicon_180x180.png"
      />
      <link
        rel="icon shortcut"
        type="image/x-icon"
        href="/favicon/favicon.ico"
      />
      <link
        rel="mask-icon"
        href="/favicon/safari-pinned-tab.svg"
        color="#000000"
      />

      <meta property="og:title" content="Oddular" />
    </Head>
  );
};

export default Meta;

import { mode } from '@chakra-ui/theme-tools';

function baseStyle(props: Record<string, any>) {
  const bg = mode(`blackAlpha.100`, `whiteAlpha.100`)(props);
  const hoverBg = mode(`blackAlpha.200`, `whiteAlpha.200`)(props);
  const activeBg = mode(`blackAlpha.300`, `whiteAlpha.300`)(props);

  return {
    borderRadius: '100%',
    transition: 'all 0.2s',
    marginTop: ".25rem",
    bg: bg,
    _disabled: {
      opacity: 0.4,
      cursor: 'not-allowed',
      boxShadow: 'none',
    },
    _hover: { bg: hoverBg },
    _active: { bg: activeBg },
    _focus: {
      boxShadow: 'outline',
    },
  };
}

const sizes = {
  lg: {
    w: '36px',
    h: '36px',
    fontSize: '16px',
  },
  md: {
    w: '30px',
    h: '30px',
    fontSize: '12px',
  },
  sm: {
    w: '24px',
    h: '24px',
    fontSize: '10px',
  },
};

const defaultProps = {
  size: 'md',
};

export default {
  baseStyle,
  sizes,
  defaultProps,
};

import Menu from './_menu';
import Drawer from './_drawer';
import CloseButton from './_close_button';
import Heading from './_heading';

const components = {
  Menu,
  Drawer,
  CloseButton,
  Heading,
  Button: {
    baseStyle: {
      lineHeight: '1.2',
      borderRadius: 'xl',
      fontWeight: 'semibold',
      _focus: {
        boxShadow: 'outline',
      },
      _disabled: {
        opacity: 0.4,
        cursor: 'not-allowed',
        boxShadow: 'none',
      },
      _hover: {
        _disabled: {
          bg: 'initial',
        },
      },
    },
    sizes: {
      xl: {
        h: '56px',
        fontSize: 'lg',
        px: '32px',
      },
      lg: {
        h: 12,
        minW: 12,
        fontSize: 'lg',
        px: 6,
      },
      md: {
        h: 10,
        minW: 10,
        fontSize: 'md',
        px: 4,
      },
      sm: {
        h: 8,
        minW: 8,
        fontSize: 'sm',
        px: 3,
      },
      xs: {
        h: 6,
        minW: 6,
        fontSize: 'xs',
        px: 2,
      },
    },
    variants: {
      glass: {
        bg: 'whiteAlpha.300',
        borderRadius: '100px',
        px: '1rem',
        color: 'white',
        _hover: {
          bg: 'whiteAlpha.400',
        },
      },
    },
  },
  Link: {
    baseStyle: {
      cursor: 'pointer',
      textDecoration: 'none',
      color: 'gray.800',
      _hover: {
        textDecoration: 'none',
        color: 'gray.900'
      },
      _focus: {
        boxShadow: 'outline',
      },
      _disabled: {
        opacity: 0.4,
        cursor: 'not-allowed',
        textDecoration: 'none',
      },
    },
  },
};

export default components;
